:root {
    --playback-dark-background: black;
}

.playback-container-wrapper {
    position: relative;
}

.playback-container-wrapper:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX( -50%);
    height: 100%;
    width: 100vw;
    background: var(--playback-dark-background);
}

.playback-metadata-description {
    margin: 0;
    font-weight: bold;
    font-size: 28px;
}

.playback-promo-container {
    display: block;
    user-select: none;
    height: 100px;
    background-color: white;
    padding: 2px;
    border: 1px solid #c5c6cc;
}

.playback-promo-container img {
    width: auto;
    height: 100%;
    margin: auto;
    display: block;
}

a.playback-metadata-game {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 13px;
    color: #B2B2B2;
}

.playback-metadata-tags {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
}

.playback-tag-link {
    display: inline;
}

.playback-tag-link:not(:last-child):after {
    content: "|";
    color: #B2B2B2;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 10px;
    vertical-align: top;
}

@media (max-width: 1216px) {
    .playback-promo-container {
        height: 100px;
    }
}

@media (max-width: 1046px) {
    .playback-metadata-description {
        font-size: 23px;
    }
    .playback-promo-container {
        height: unset;
    }
    .playback-promo-container img {
        width: calc(100% - 8px);
        height: auto;
    }
}

@media (min-width: 768px) {
    .playback-metadata-description {
        margin-right: 15px;
    }
}

@media (max-width: 767px) {
    .playback-metadata-content {
        padding-left: 15px;
        padding-right: 15px;
    }

    .playback-promo-container {
        height: auto;
        border-radius: 6px;
        padding: 8px;
    }

    .playback-promo-container img {
        width: calc(100% - 40px);
    }

    .playback-metadata-description {
        font-size: 17px;
    }

    .playback-metadata-tags {
        font-size: 10px;
    }

    .playback-tag-link:not(:last-child):after {
        font-size: 9px;
    }

    a.playback-metadata-game {
        font-size: 11px;
    }

    .playback-metadata-content .voffset2 {
        margin-top: 5px;
    }
}

@media (max-width: 480px) {
    .playback-row {
        margin-left: -5px;
        margin-right: -5px;
    }

    .playback-promo-container img {
        width: calc(100% - 10px);
    }
}
