
.video-not-available-container {
    width: 100%;
    height: 0;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    position: absolute;
    top: 0;
    pointer-events: none;
    background: black;
}

.video-js .video-not-available-container {
    background: none;
}

.video-not-available-container > * {
    position: absolute;
    left: 0;
    width: 100%;
}

.video-not-available-msg {
    z-index: 1;
    top: 35%;
    text-align: center;
    font-size: larger;
    background-color: var(--primary-bg-color);
    padding: 20px 0;
    color: white;
}

.video-requires-stadium-cont {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--primary-bg-color);
    color: white;
    padding: 12px 0;
}

.checking-location-cont {
    display: flex;
    align-items: center;
    gap: 7px;
}

.checking-location-info {
    text-align: center;
    padding: 0 10px;
}

.small-loading-icon {
    position: relative;
    width: 27px;
    height: 27px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    overflow: hidden;
}

.small-loading-icon::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-image: conic-gradient(
        var(--secondary-bg-color) 90deg,
        transparent 0deg
    );
    animation: rotate 1.5s linear infinite;
    border-radius: 50%;
}

.small-loading-icon::after {
    content: "";
    position: absolute;
    inset: 4px;
    background: var(--primary-bg-color);
    border-radius: 50%;
}

.outside-stadium-icon {
    position: relative;
    font-size: 40px;
}

.outside-stadium-cross-line {
    position: absolute;
    top: calc(50% - 10px);
    left: -7px;
    margin: auto;
    transform: rotate(150deg);
    width: 55px;
    border-top: 1px solid white;
}

.outside-stadium-title {
    font-size: 17px;
    margin-bottom: 15px;
}

.outside-stadium-info {
    margin-bottom: 3px;
}

.check-location-btn {
    margin-top: 10px;
    padding: 5px 12px;
    background-color: var(--secondary-bg-color);
    border: none;
    border-radius: 3px;
}

.check-location-btn:hover {
    filter: brightness(80%);
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 720px) {
    .outside-stadium-title {
        font-size: 13px;
        margin-bottom: 10px;
    }
    .outside-stadium-info {
        font-size: 10px;
        margin-bottom: unset;
        text-align: center;
        padding: 0 10px;
        margin-bottom: 2px;
    }
}

@media (max-width: 500px) {
    .video-not-available-msg {
        top: 20%;
    }
    .small-loading-icon {
        width: 20px;
        height: 20px;
    }
    .small-loading-icon::after {
        inset: 3px;
    }
    .checking-location,
    .checking-location-info {
        font-size: 10px;
    }
    .outside-stadium-title {
        font-size: 12px;
        margin-top: -10px;
    }
    .outside-stadium-info {
        font-size: 8px;
    }
    .check-location-btn {
        margin-top: 5px;
        padding: 5px 10px;
        font-size: 12px;
    }
}

@media (max-width: 380px) {
    .video-not-available-msg {
        top: 25%;
    }
}

@media (max-width: 300px) {
    .video-not-available-msg {
        top: 0;
        height: 100%;
    }
}

.video-js .video-not-available-msg {
    opacity: 0;
    transition: opacity 0.3s 0s ease;
}

.video-js.vjs-error .video-not-available-msg, .video-js.vjs-waiting .video-not-available-msg {
    opacity: 100;
    transition: opacity 0.3s 3s ease;
}

.btn.open-subscription-btn {
    margin-top: 10px;
    pointer-events: initial !important;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    color: white !important;
    background-color: var(--pagination-color);
    border: none !important;
}

.btn.open-subscription-btn:hover,
.btn.open-subscription-btn:focus {
    background-color: var(--pagination-hover-color) !important;
}