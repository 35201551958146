.playlist-collection > .container-fluid {
    /* Offsets the 3px padding on each playlist entry, normally 15px */
    padding-left: 9px;
    padding-right: 9px;
}

.playlist-collection-title {
    color: #272727;
    font-size: 16px;
    font-weight: 700;
    font-variant: small-caps;
    border: 0;
    border-bottom: 1px solid #373737;
    margin-bottom: 10px;
}

.playlist-collection {
    margin-top: -6px;
    margin-bottom: -6px;
}
