.socials-btn {
    position: relative;
    display: inline-block;
    width: 40px;
    min-height: 1px;
}

.socials-btn:not(:first-of-type) {
    margin-left: 15px;
}

