.toppserien-sponsors-list,
.logo-and-social {
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
}

.toppserien-sponsors-list {
    display: flex;
    justify-content: space-between;
    padding: 15px 25px;
    background-color: white;
}

.toppserien-sponsor {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.toppserien-sponsor img {
    height: 47px;
}

.toppserien-sponsor div {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
}

.footer {
    padding: 25px;
    background-color: var(--primary-bg-color);
}

.logo-and-social {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.footer-main-logo {
    height: 55px;
}

.social-media-list {
    display: flex;
    align-items: center;
    gap: 15px;
}

.footer a:link,
.footer a:visited {
    text-decoration: none;
    color: white;
}

.social-media {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    color: white;
    background-color: #6f4592;
    font-size: 17px;
    cursor: pointer;
    transition: background-color 0.3s ease 0s;
}

.to-toppserien {
    display: flex;
    align-items: center;
    color: white;
    gap: 5px;
    margin-left: 55px;
    padding: 10px;
    border-radius: 3px;
    background-color: #2b163b;
    font-size: 12px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease 0s;
}

.to-toppserien img {
    height: 22px;
}

.footer-links {
    margin: 0 auto;
    display: flex;
    width: fit-content;
    margin-bottom: 15px;
}

.footer-links a {
    color: white;
    padding: 0 15px;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    text-decoration: none;
}

.footer-links a:last-child {
    border-right: none;
}

.footer-language-list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.footer-language {
    height: 20px;
    border-radius: 3px;
    cursor: pointer;
}

/* HOVER */
.footer-links a:hover,
.footer-language:hover {
    filter: brightness(80%);
}
.social-media:hover {
    color: var(--primary-bg-color) !important;
    background-color: white;
}
.to-toppserien:hover {
    background-color: white;
    color: var(--primary-bg-color) !important;
    text-decoration: none;
}

/* MEDIA QUERIES */
@media (max-width: 998px) {
    .toppserien-sponsor img {
        height: 32px;
    }    
    .toppserien-sponsor div {
        font-size: 10px;
    }
}
@media (max-width: 720px) {
    .toppserien-sponsors-list {
        display: flex;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
        padding: 15px;
    }
    .footer {
        padding: 15px;
    }
    .footer-main-logo {
        height: 45px;
    }
    .logo-and-social {
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
    .social-media {
        width: 27px;
        height: 27px;
        font-size: 12px;
    }
    .to-toppserien {
        margin-left: unset;
        font-size: 10px;
        padding: 7px;
    }
    .to-toppserien img {
        height: 17px;
    }
    .footer-links a {
        font-size: 12px;
        padding: 0 15px;
    }
    .footer-language {
        height: 17px;
    }
}

@media (max-width: 480px) {
    .toppserien-sponsors-list {
        padding: 10px 0;
        gap: 20px 0;
    }
    .toppserien-sponsor {
        width: 45%;
        gap: 5px;
    }
    .toppserien-sponsor img {
        height: 22px;
    }    
    .toppserien-sponsor div {
        font-size: 9px;
    }
    .footer-main-logo {
        height: 32px;
    }
    .footer {
        padding: 15px 10px;
    }
    .social-media-list {
        gap: 7px;
    }
    .footer-links {
        margin: unset;
        flex-direction: column;
        width: fit-content;
        margin-bottom: 7px;
        gap: 10px;
    }
    .footer-links a {
        padding: unset;
        border: none;
    }
    .social-media {
        width: 25px;
        height: 25px;
        font-size: 12px;
    }
    .to-toppserien img {
        height: 15px;
    }
    .footer-language {
        height: 14px;
    }
}