.games-screen-header .games-screen-filters {
    display: inline;
    float: right;
    text-transform: uppercase;
    font-weight: bold;
}

.games-screen-filters .btn,
.games-screen-filters .input-group-addon {
    font-weight: bold !important;
}

.games-screen-filters .form-group .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.games-round-header {
    color: var(--primary-light-font-color);
    background: var(--primary-bg-color);
    font-weight: bold;
    font-size: 14px;
    line-height: 33px;
}

.game-entry:not(:last-child) {
    border-bottom: 1px solid lightgrey;
}

.game-entry {
    display: flex;
    align-items: center;
    padding: 0 12px;
}

.game-entry .game-date {
    font-size: 1.4rem;
    text-transform: uppercase;
}

.game-entry .game-data,
.game-entry .game-date {
    position: relative;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
}

.game-entry .game-time {
    padding-top: 7px;
    line-height: 3.2em;
    font-size: 1.5rem;
}

.game-entry a.game-data {
    flex-grow: 1;
    line-height: 1.6em;
}

.game-entry a.game-data.game-has-started {
    padding-right: 38px;
}

img.game-entry-play-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 27px;
    width: 27px;
}
.game-team-cont {
    display: flex;
    flex-direction: column;
    gap: 7px;
    flex-grow: 1;
    width: 100%;
}

.game-team {
    display: flex;
    align-items: center;
    gap: 12px;
    height: 50%;
    font-size: 1.5rem;
    margin-left: 20px;
    flex-grow: 1;
}

.game-team img {
    height: 27px;
}

.game-score {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    margin-right: 10px;
}

.game-score-single {
    display: flex;
    align-items: center;
    height: 50%;
    font-size: 1.5rem;
}

@media (max-width: 768px) {
    .game-entry .game-date,
    .game-entry .game-time {
        font-size: 1.2rem;
    }
    .game-team {
        gap: 10px;
        font-size: 1.4rem;
    }
    .game-team img {
        height: 22px;
    }
    .game-score {
        margin-right: unset;
    }
    .game-score-single {
        font-size: 1.4rem;
    }
    img.game-entry-play-icon {
        height: 22px;
        width: 22px;
    }
}

@media (max-width: 480px) {
    .game-entry .game-date {
        width: 75px;
    }
    .game-entry .game-date,
    .game-entry .game-time {
        font-size: 1rem;
    }
    .game-team {
        justify-content: flex-start;
        gap: 5px;
        width: 180px;
        font-size: 1.2rem;
        margin-left: 10px;
    }
    .game-score {
        margin-right: -3px;
    }
    .game-score-single {
        font-size: 1.2rem;
    }
    .game-team img {
        height: 20px;
    }
}