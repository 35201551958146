.playback-timeline-header {
    display: table;
    margin: auto;
    left: 50%;
    right: 50%;
    padding: 10px;
    background: white;
    border-radius: 6px;
    border: 1px solid #c5c6cc;
}

.playback-timeline-header > * {
    display: table-cell;
    vertical-align: middle;
}

.playback-timeline-header-score {
    font-size: 24px;
    font-weight: bold;
}

.playback-timeline-header-logo img {
    height: 50px;
    width: auto;
}

.playback-timeline-header-is-live {
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1em;
}

.timeline-event-clock {
    text-align: center;
    font-size: 1.5rem;
    width: 52px;
    position: relative;
    display: table-cell;
    vertical-align: middle;
    font-weight: bold;
    color: #909090;
}

.game-timeline-event > .timeline-event-clock > span {
    padding: 10px 4px;
    background-color: var(--body-background-color);
}

.game-timeline-event {
    width: 100%;
    user-select: none;
}

.game-timeline > *:not(:last-of-type) {
    margin-bottom: 12px;
}

span.substitution-label {
    min-width: 1.5em;
    text-align: left;
    display: inline-block;
}

.timeline-event-card img {
    height: auto;
    width: 100%;
    margin-top: -3px;
}

.timeline-event-play,
.timeline-event-icon {
    width: 40px;
    padding: 10px;
}

.game-timeline-event {
    position: relative;
    display: table;
}

.timeline-event-left .game-timeline-event {
    float: left;
}

.timeline-event-right .game-timeline-event {
    float: right;
}

.timeline-event-card {
    position: relative;
    display: table;
    max-height: 60px;
    width: 100%;
    padding: 12px 6px;
    border-radius: 6px;
    border: 1px solid #c5c6cc;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.29px;
    color: var(--primary-dark-font-color) !important;
    background: white;
}

.timeline-event-card > * {
    position: relative;
    display: table-cell;
    vertical-align: middle;
}

.timeline-event-card.event-card-small {
    padding: 1px 6px;
}

.timeline-event-description {
    font-size: 1.5rem;
}

.timeline-event-left .timeline-event-description {
    text-align: right;
}

.timeline-event-center .timeline-event-description,
.timeline-event-right .timeline-event-description {
    text-align: left;
}

.timeline-event-title {
    font-size: 1.4rem;
    font-weight: normal;
}

.event-goal .timeline-event-card {
    background-color: var(--pagination-color);
    border-color: var(--pagination-color);
    color: var(--primary-light-font-color) !important;
}

.event-penalty .timeline-event-card,
.event-penalty-miss .timeline-event-card {
    background-color: #F47A00;
    border-color: #F47A00;
    color: var(--primary-light-font-color) !important;
}

.event-misc .timeline-event-card {
    border-color: var(--primary-bg-color);
    color: var(--primary-light-font-color) !important;
    background: repeating-linear-gradient(
            45deg,
            var(--stripe-bg-color),
            var(--stripe-bg-color) 5px,
            var(--primary-bg-color) 5px,
            var(--primary-bg-color) 15px
    );
}


.timeline-event-card.event-card-text-only {
    background-color: #eee;
}

.event-penalty .timeline-event-card .timeline-event-clock,
.event-misc .timeline-event-card .timeline-event-clock,
.event-penalty-miss .timeline-event-card .timeline-event-clock,
.event-goal .timeline-event-card .timeline-event-clock {
    color: white;
}

.event-penalty .timeline-event-card .timeline-event-play,
.event-misc .timeline-event-card .timeline-event-play,
.event-penalty-miss .timeline-event-card .timeline-event-play,
.event-goal .timeline-event-card .timeline-event-play {
    filter: invert(1);
}

.event-is-playing-label {
    display: block;
    position: absolute;
    bottom: 100%;
    left: 0;
    padding: 6px 16px;
    border-radius: 6px 6px 0 0;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--primary-light-font-color);
    background-color: var(--lighter-bg-color);
}

.event-is-playing-label img {
    height: 20px;
    width: auto;
    margin-right: 10px;
    filter: invert(1);
}

/* Vertical line down the middle */
.game-timeline:not(:empty):after {
    content:"";
    position: absolute;
    z-index: -1;
    top: 30px;
    bottom: 30px;
    left: 50%;
    border-left: 1px solid #c5c6cc;
    transform: translate(-50%);
}

@media (min-width: 768px) {

    .timeline-event-right + .timeline-event-right.event-is-playing,
    .timeline-event-left + .timeline-event-left.event-is-playing {
        margin-top: 43px;
    }

    .timeline-event-right .event-is-playing-label {
        right: 0;
        left: initial;
    }

    .timeline-event-right.event-is-playing .timeline-event-card {
        border-top-right-radius: 0;
    }

    .timeline-event-left.event-is-playing .timeline-event-card {
        border-top-left-radius: 0;
    }

    .game-timeline-event {
        width: calc(50% + 26px); /* Half the width of timeline clock */
    }

    .playback-timeline-header:after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        z-index: -1;
        border-bottom: 1px solid #c5c6cc;
    }
    .playback-timeline-header-logo {
        padding-left: 40px;
        padding-right: 40px;
    }
    .playback-screen .game-timeline {
        /* No idea why I need to do this, but alignment gets wrong */
        margin-right: -15px;
        margin-left: -15px;
    }

    .timeline-event-center .game-timeline-event {
        margin-left: auto;
        margin-right: auto;
        max-width: 320px
    }
}


@media (max-width: 767px) {
    .timeline-event-card {
        font-size: 12px;
    }

    .timeline-event-clock {
        width: 33px;
        font-size: 12px;
    }

    .timeline-event-description {
        font-size: 13px;
        font-weight: bold;
    }

    .timeline-event-icon {
        padding-left: 0;
        width: 30px;
    }

    .playback-timeline-header {
        width: 100%;
        padding: 8px;
    }

    .playback-timeline-header-logo:first-of-type {
        text-align: right;
    }

    .playback-timeline-header-logo:last-of-type {
        text-align: left;
    }

    .game-timeline > *:not(:last-of-type) {
        margin-bottom: 10px;
    }

    .playback-timeline-header-logo img {
        height: 38px;
    }

    .playback-timeline-header-score {
        font-size: 20px;
        width: 110px;
    }

    .event-is-playing .timeline-event-card {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-top: none;
    }

    .event-is-playing-label {
        width: 100%;
        padding: 6px 6px;
        text-align: center;
        height: 33px;
    }

    .event-is-playing {
        margin-top: 43px;
    }
}
