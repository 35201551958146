
.h-plus-home-promo {
    font-size: large;
}

img.h-plus-promo-logo {
    width: 350px;
    max-width: 50%;
    height: auto;
}

@media (min-width: 768px) {
    .h-plus-home-promo {
        margin: 8px 0;
    }
}

@media (max-width: 640px) {
    .h-plus-home-promo {
        font-size: 12px;
    }
    .h-plus-home-promo ul {
        padding-inline-start: 30px;
    }
}
