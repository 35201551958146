
.mobile-playlist-collection {
    user-select: none;
    display: block;
    overflow: hidden;
}

.mobile-playlist-collection-inner {
    transition: transform 0.4s ease 0s;
    width: 990000px;
}

.mobile-playlist-entry {
    min-height: 1px;
    width: 80vw;
    display: inline-block;
}