
@font-face {
    font-family: DINNext-Regular;
    src: url("../../fonts/DINNext-Regular.woff2") format("woff2");
}

@font-face {
    font-family: DINNext-CondensedBla;
    src: url("../../fonts/DINNext-CondensedBla.woff2") format("woff2");
}

/* Define color overrides */
:root {
    --primary-font-family: "DINNext-Regular";
    --header-font-family: "DINNext-CondensedBla";
    --primary-bg-color: #0d4fa7;
    --secondary-bg-color: #00aa79;
    --stripe-bg-color: #0c4797;
    --lighter-bg-color: #116ae0;
    --pagination-color: #0d4fa7;
    --pagination-hover-color: #0f6ae3;
    --default-link-color: #174B93;
    --hover-link-color: #12326e;
    --playlist-fade-color: rgba(7, 32, 90, 0.85) !important;
    --article-fade-first:  rgba(7, 32, 90, 0.30) !important;
    --article-fade-second: rgba(7, 32, 90, 0.90) !important;
    --playback-dark-background: #00081C !important;
}

.navbar, .content-block-fullpage-title , .content-block-title {
    font-family: var(--header-font-family);
}

.content-block-fullpage-title , .content-block-title {
    color: var(--primary-bg-color);
    text-transform: uppercase;
}

.game-team div,
.game-card-team-and-logo div,
.game-score-single {
    padding-top: 3px !important;
}

.game-card-team-and-logo div,
.game-card-score {
    font-size: 1.5rem !important;
    font-weight: bold;
}

.game-team div,
.game-score-single {
    font-size: 1.6rem !important;
}

@media (max-width: 480px) {
    .game-team div,
    .game-score-single {
        font-size: 1.4rem !important;
    }
}
