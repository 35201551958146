@font-face {
    font-family: BBStrataProSEF;
    font-weight: bold;
    src: url("../fonts/BBStrataProSEF-Bold.otf") format("opentype");
}

@font-face {
    font-family: Gilroy;
    src: url("../fonts/Gilroy-Regular.otf") format("opentype");
}

@font-face {
    font-family: Gilroy;
    font-weight: bold;
    src: url("../fonts/Gilroy-Bold.otf") format("opentype");
}

@font-face {
    font-family: Gilroy;
    font-style: italic;
    src: url("../fonts/Gilroy-RegularItalic.otf") format("opentype");
}

@font-face {
    font-family: Gilroy;
    font-weight: bold;
    font-style: italic;
    src: url("../fonts/Gilroy-BoldItalic.otf") format("opentype");
}
