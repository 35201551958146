
.team-logo {
    width: 90px;
    height: auto;
    user-select: none;
}

.content-block.game-screen-header {
    border: 1px solid #c5c6cc;
    text-align: center;
    border-radius: 6px;
}

.team-name {
    text-transform: uppercase;
    font-weight: bold;
    padding-top: 0.5em;
    font-size: 1.7rem;
}

.team-score {
    font-weight: bold;
    font-size: 42px;
}

.game-status {
    text-transform: uppercase;
    font-size: smaller;
    font-weight: bold;
    font-size: 1.5rem;
}

.game-date {
    font-size: 1.5rem;
}

@media (max-width: 767px) {
    .team-logo {
        width: 70px;
    }
    .content-block.game-screen-header {
        font-size: small;
    }
    .team-score {
        font-size: 30px;
    }
}

@media (max-width: 480px) {
    .team-name,
    .game-date,
    .game-status {
        font-size: smaller;
    }
}

.loading-icon {
    border-color: var(--primary-bg-color);
    border-top-color: rgba(0,0,0,0.2);
    border-left-color: rgba(0,0,0,0.2);
}
