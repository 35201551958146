
.page-wrap.sef-layout {
    min-height: 100%;
    /* equal to footer height */
    margin-bottom: -372px;
}

.sef-footer-container, .page-wrap.sef-layout:after {
    height: 372px;
}

.sef-footer-container {
    width: 100%;
    font-weight: bold;
    font-size: 13px;
    background-color: #fff;
    border-top: 1px solid #ededed;
}

.row.footer-sponsors {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.footer-sponsors > div {
    border: 1px solid #ededed;
    height: 157px;
    padding: 45px 0;
}

.footer-sponsors > div img {
    width: auto;
    display: block;
    margin: 0 auto;
    margin-bottom: 15px;
    max-height: 50px;
    max-width: 100%;
}

.footer-sponsors > div span {
    display: block;
    font-family: Gilroy-Regular,sans-serif;
    font-size: 10px;
    color: #6c6c6c;
}

.sef-footer-container .copyright {
    background-color: var(--primary-bg-color);
    color: var(--primary-light-font-color);
    text-transform: uppercase;
    padding: 20px;
    height: 58px;
}


.sef-footer-container .footer-logo {
    padding: 0;
}

.sef-footer-container .footer-logo .footer-locale-flag {
    margin-top: 0;
    margin-bottom: 0;
}

.sef-footer-container .footer-logo > img,
.sef-footer-container .footer-logo .footer-locale-flag {
    width: auto;
    height: 18px;
    border-radius: 0;
}

.sef-footer-container .footer-copyright {
    padding: 0;
    color: #ffd600;
    font-size: 11px;
    line-height: 18px;
}

.sef-footer-container .footer-links > a {
    color: #fff;
    font-size: 11px;
    padding: 0 10px;
    line-height: 18px;
}

@media (max-width: 767px) {
    .sef-footer-container .footer-copyright,
    .sef-footer-container .footer-logo,
    .sef-footer-container .footer-links {
        text-align: center;
        padding: 8px 0;
    }
    .sef-footer-container, .page-wrap.sef-layout:after {
        height: 1120px;
    }
    .page-wrap.sef-layout {
        margin-bottom: -1120px;
    }
    .sef-footer-container .copyright {
        height: 178px;
    }
    .sef-footer-container .footer-logo > img {
        height: 24px;
    }
}
