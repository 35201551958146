
.content-block {
    margin-bottom: 30px;
}

.content-block.smaller-margin {
    margin-bottom: 20px;
}

.content-block:not(.content-block-no-fill) {
    background-color: var(--content-background-color);
    padding-top: 15px;
    padding-bottom: 15px;
    border: 1px solid #E9EAEF;
}

.content-block:not(.content-block-no-fill).smaller-padding {
    padding-bottom: 10px;
    padding-top: 10px;
}

.content-block-title {
    font-size: 3.2rem;
    font-weight: bold;
    line-height: 2em;
    margin-top: 2px;
    margin-bottom: 6px;
}

.content-block-fullpage-title {
    font-size: 3rem;
    line-height: 30px;
    font-weight: 500;
    display: block;
    min-height: 36px;
}

.content-block-filters {
    display: inline;
    float: right;
    font-weight: bold;
}

.content-block-filters .btn,
.content-block-filters .filter-label {
    font-weight: bold !important;
    overflow: hidden;
    text-align: left;
}

.content-block-filters:not(.fullwidth-filter) .form-group {
    margin-left: 15px;
}

.content-block-filters .filter-label {
    text-transform: uppercase;
    font-size: 11px;
    margin-right: 4px;
}

span.filter-label {
    font-size: 1.4rem !important;
}

.fullwidth-filter,
.fullwidth-filter button {
    width: 100%;
}

.fullwidth-filter .btn-group,
.fullwidth-filter .react-bootstrap-daterangepicker-container {
    width: 240px;
    max-width: 100%;
}

.dropdown-toggle.btn,
.btn-default {
    font-size: 1.4rem;
}

.fullwidth-filter .filter-label {
    display: block;
    margin-bottom: 2px;
}

.form-group {
    margin-bottom: 0;
}

@media (max-width: 768px) {

    .content-block-title {
        font-size: 2.5rem;
        line-height: 1.6em;
        margin-top: 0;
        margin-bottom: 4px;
    }
    .content-block {
        margin-bottom: 20px;
    }
    .content-block.smaller-margin {
        margin-bottom: 12px;
    }
    .content-block:not(.content-block-no-fill).smaller-padding {
        padding-bottom: 6px;
        padding-top: 6px;
    }
    .content-block-fullpage-title {
        font-size: 18px;
        min-height: 30px;
        margin-top: 3px;
    }
    span.filter-label,
    .dropdown-toggle.btn,
    .btn-default {
        font-size: 1.2rem !important;
    }
}

@media (min-width: 768px) {
    .content-block {
        border-radius: 8px;
    }
}

@media (max-width: 480px) {
    .content-block-title {
        font-size: 2rem;
    }
    .content-block-filters button,
    .content-block-filters .btn {
        font-size: 11px;
    }
}
