/* Trick to make the footer at the bottom regardless */
html, body, #root, .full-body-container {
    height: 100%;
}

.page-wrap {
    min-height: 100%;
    /* equal to footer height */
    margin-bottom: -160px;
}

.page-wrap:after {
    content: "";
    display: block;
}

.footer-container, .page-wrap:after {
    min-height: 160px;
}

.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 160px;
    width: 100%;
    background: var(--primary-bg-color);
    font-weight: bold;
    font-size: 13px;
    border: 1px solid green;
}

.footer-locale-flag {
    border-radius: 4px;
    width: 34px;
    margin: 5px;
    cursor: pointer;
}

a.footer-terms-and-cond {
    color: #00D4FA;
}

.footer-copyright-text {
    color: var(--primary-light-font-color);
}

.eliteserien-footer {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin: 25px 0;
}

.footer-media-list {
    display: flex;
    margin: 0 auto;
    width: fit-content;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px 20px;
}

.footer-media-list a {
    float: right;
}

.footer-eliteserien-logo {
    height: 35px;
}

.footer-media-single {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    color: white;
    font-size: 18px;
}

.footer-terms-and-condition {
    color: white;
    font-size: 14px;
}

.footer-media-single:hover,
.footer-terms-and-condition:hover {
    text-decoration: none;
    color: var(--secondary-bg-color);
}

@media (max-width: 768px) {
    .eliteserien-footer {
        gap: 20px;
        padding: 0 5px;
    }
    .footer-media-single {
        gap: 5px;
        font-size: 16px;
    }
    .footer-terms-and-condition {
        font-size: 12px;
    }
}