.voffset  { margin-top: 2px; }
.voffset1 { margin-top: 5px; }
.voffset2 { margin-top: 10px; }
.voffset3 { margin-top: 15px; }
.voffset4 { margin-top: 30px; }
.voffset5 { margin-top: 40px; }
.voffset6 { margin-top: 60px; }
.voffset7 { margin-top: 80px; }
.voffset8 { margin-top: 100px; }
.voffset9 { margin-top: 150px; }

.vpadding  { margin-bottom: 2px; }
.vpadding1 { margin-bottom: 5px; }
.vpadding2 { margin-bottom: 10px; }
.vpadding3 { margin-bottom: 15px; }
.vpadding4 { margin-bottom: 30px; }
.vpadding5 { margin-bottom: 40px; }
.vpadding6 { margin-bottom: 60px; }
.vpadding7 { margin-bottom: 80px; }
.vpadding8 { margin-bottom: 100px; }
.vpadding9 { margin-bottom: 150px; }

.voffset1-reactive { margin-top: 5px; }
.voffset2-reactive { margin-top: 10px; }
.voffset3-reactive { margin-top: 15px; }
.voffset4-reactive { margin-top: 30px; }

@media (max-width: 767px) {
    .voffset1-reactive { margin-top: 2px; }
    .voffset2-reactive { margin-top: 5px; }
    .voffset3-reactive { margin-top: 8px; }
    .voffset4-reactive { margin-top: 12px; }
}

.max-width { width: 100%; }
.max-height { height: 100%; }

.float-left { float: left; }
.float-right { float: right; }

.clickable { cursor: pointer; }

/*svg.glyph-white g { fill: white; }*/
svg.font-colored g { fill: currentColor; }

.text-bold { font-weight: bold; }

a.black-link {
    text-decoration: underline;
    color: #333;
}

a.black-link:hover {
    color: black;
}

td.min-column {
    width: 1%;
    white-space: nowrap;
}
