:root {
    --article-fade-first:  rgba(0,8,30, 0.30);
    --article-fade-second: rgba(0,8,30, 1);
}

.featured-article-container {
    position: relative;
    display: block;
    max-width: 1920px;
    margin: auto;
    margin-bottom: -55px;
}

.featured-image {
    display: block;
    position: relative;
    width: 100%;
    height: calc(86vh - 88px);
    max-height: 56.25vw;
    background-size: cover;
    background-color: var(--playback-dark-background);
    background-repeat: no-repeat;
    background-position: center;
}

.featured-image > img {
    width: 100%;
    height: 100%;
}

.featured-article-overlay {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    bottom: 0;
    background: transparent;
    background: linear-gradient(180deg, transparent 0%, var(--article-fade-first) 40%, var(--article-fade-second) 85%);
}

.featured-article-metadata {
    position: absolute;
    width: 100%;
    bottom: 70px;
    font-weight: bold;
    color: var(--primary-light-font-color);
}

.featured-article-game-text {
    font-size: 2rem;
    margin-bottom: 7px;
    text-transform: uppercase;
    color: var(--secondary-bg-color);
}

.featured-article-description {
    font-size: 50px;
    letter-spacing: 0;
    line-height: 1.1em;
    margin-bottom: 15px;
}

button.show-clip-btn {
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    color: white !important;
    background-color: var(--pagination-color);
    border: none !important;
}

button.show-clip-btn > span:last-child {
    font-size: larger;
    line-height: 1em;
}

button.show-clip-btn:hover,
button.show-clip-btn:focus {
    background-color: var(--pagination-hover-color);
}

@media (max-width: 1200px) {
    .featured-article-description {
        font-size: 36px;
    }
}

@media (max-width: 960px) {
    .featured-article-description {
        font-size: 30px;
    }
    .featured-article-game-text {
        font-size: 1.2rem;
        margin-bottom: 2px;
    }
}

@media (max-width: 767px) {
    .featured-article-description {
        font-size: 2.5rem;
        margin-bottom: 10px;
    }
    .featured-article-metadata {
        bottom: 15px;
    }
    .featured-article-container {
        margin-bottom: 0;
    }
    button.show-clip-btn {
        font-size: 12px;
    }
}

@media (max-width: 540px) {
    .featured-article-description {
        font-size: 17px;
    }
    .featured-article-game-text {
        font-size: 11px;
    }
    .featured-image {
        max-height: 75vw;
    }
    button.show-clip-btn {
        font-size: 10px;
    }
}
