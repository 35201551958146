* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.next-main {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.65);
    width: 100%;
    top: 0;
    left: 0;
    bottom: 30px;
}
.next-playlist {
    width: 40%;
    min-width: 420px;
}

.next-font {
    text-align: center;
    font-size: 17px;
    margin-bottom: 10px;
}

.cancel-btn-container {
    display: flex;
    margin-top: 10px;
}

.replay-btn-container {
    font-size: 20px;
    margin-top: 15px;
}

.video-js button.cancel-btn,
.video-js button.replay-btn {
    display: flex;
    background-color: var(--primary-bg-color);
    border: 1px solid rgba(255,255,255,0.25);
    text-align: center;
    align-items: center;
    padding: 10px;
}

.video-js button.cancel-btn {
    font-size: 17px;
    margin-left: 15px;
}

.next-countdown {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 17px;
    margin-bottom: 15px;
}

.countdown-number {
    font-size: 20px;
    margin-left: 5px;
}

.replay-icon {
    margin-right: 3px;
}

/* HOVER */
.cancel-btn:hover,
.replay-btn:hover {
    background-color: var(--secondary-bg-color)!important;
    color: var(--primary-dark-font-color);
}

@media all and (max-width: 768px) {
    .next-playlist {
        min-width: 0px;
        width: 60%;
    }
    .next-font,
    .next-countdown,
    .cancel-btn,
    .replay-btn,
    .countdown-number {
        font-size: 15px!important;
    }
    .next-font{
        margin-bottom: 5px;
    }
    .next-countdown {
        margin-bottom: 2px;
    }
    .cancel-btn-container {
        margin: 3px 0;
    }
    .replay-btn-container {
        margin-top: 0;
    }
    .cancel-btn {
        margin-left: 7px;
        margin-bottom: 3px;
    }
    .cancel-btn, .replay-btn {
        padding: 5px 7px;
    }
    .playlist-entry .col-xs-12{
        padding: 0 5px;
        font-size: 1.2em;
    }
}

@media all and (max-width: 565px) {
    .next-countdown,
    .cancel-btn,
    .replay-btn,
    .countdown-number {
        font-size: 10px!important;
    }
    .cancel-btn {
        padding: 3px 5px;
        margin-left: 7px;
        margin-bottom: 3px;
    }
    .replay-btn {
        padding: 3px 5px;
    }
    .next-font {
        display: none;
    }
    .playlist-entry .col-xs-12{
        font-size: .9em;
    }
}
