
.transform-overlay {
    bottom: 40px;
    right: 10px;
}

.transform-overlay .close-btn {
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
    color: white;
    font-size: 19px;
    font-weight: bold;
    line-height: 1;
    filter: alpha(opacity=60);
    opacity: .6;
    border: 1px solid white;
    border-radius: 100%;
    padding: 1px 5px;
    background-color: rgb(43, 51, 63);
    z-index: 1;
}

.transform-overlay .close-btn:hover {
    filter: alpha(opacity=100);
    opacity: 1;
}

.transform-overlay .btn {
    min-width: 40px;
    position: relative;
    display: inline-block;
    min-height: 1px;
    font-size: 1.3em;
}


.transform-overlay .label {
    font-size: 1.3em;
}
