
:root {
    --playlist-fade-color: rgba(0,0,0,0.8);
}

.playlist-entry {
    padding: 6px;
}

.playlist-entry-overlay-container {
    position: absolute;
    display: block;
    width: 100%;
    height: 104%; /* Some places bugs out and leaves 1-2px blank at bottom. Overflow is hidden so going over is OK */
    bottom: 0;
    border-radius: 0px 0px 4px 4px;
    background-color: var(--playlist-fade-color);
    background: linear-gradient(180deg, transparent 0%, transparent 40%, var(--playlist-fade-color) 85%);
}

.playlist-entry-title, .playlist-entry-game-title {
    display: block;
    position: absolute;
    text-decoration: none;
    font-weight: bold;
    overflow: hidden;
    line-height: 1.1em;
}

.playlist-entry-title {
    display: -webkit-box;
    bottom: 26px;
    color: white;
    color: var(--primary-light-font-color);
    max-height: 3.2em;
    font-size: 1.8rem;
    padding-bottom: 2px;
    margin-bottom: -2px;
    letter-spacing: 0;
    margin-bottom: 2px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}


.playlist-entry-game-title {
    bottom: 8px;
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 0.48px;
    color: var(--secondary-bg-color);
}

.playlist-entry-game-title > a {
    color: var(--secondary-bg-color);
}

.playlist-entry > a.black-link:not(:hover) {
    text-decoration: none;
}

.playlist-suggestion .playlist-entry-title {
    max-height: 2.2em;
    -webkit-line-clamp: 2;
    font-size: 16px;
}

/* Mobile overrides */
@media (max-width: 768px) {
    .playlist-entry-title,
    .playlist-suggestion .playlist-entry-title {
        font-size: 14px;
        bottom: 22px;
        margin-bottom: 7px;
    }
    .playlist-entry-game-title {
        bottom: 6px;
    }
    .playlist-entry-game-title a {
        font-size: 1.2rem;
    }
    .playlist-suggestion .playlist-entry-game-title {
        font-size: 7px;
    }
}

@media (max-width: 480px) {
    .playlist-entry-title,
    .playlist-suggestion .playlist-entry-title {
        font-size: 14px !important;
        bottom: 16px;
    }
    .playlist-entry-game-title a {
        font-size: 10px;
    }
}
