
.stats-menu.form-group {
    vertical-align: middle;
}

.stats-menu a {
    font-size: 0.9em;
    display: block;
}

.stats-menu-header {
    font-weight: bold;
    font-size: 1.1em;
}

.stats-more-button {
    text-align: center;
    margin-top: 2px;
}

.stats-more-button button {
    min-width: 140px;
}

.stats-container table {
    table-layout: fixed;
}

.stats-container table colgroup col:nth-child(1) {
    width: 32px;
}

.stats-container table colgroup col:nth-child(3) {
    width: 125px;
}

img.stats-play-icon {
    width: 1.35em;
    height: auto;
    float: right;
}

.stats-final-col > span {
    margin-right: 1.35em; /* Make room for play icon */
}

.stats-team-img {
    max-height: 22px;
    margin-top: -3px;
    margin-bottom: -1px;
    margin-right: 4px;
}

@media (max-width: 1047px) {

    .stats-container table {
        font-size: 13px;
    }
    .stats-container table colgroup col:nth-child(1) {
        width: 26px;
    }
    .stats-container table colgroup col:nth-child(3) {
        width: 105px;
    }
}

@media (max-width: 400px) {

    .stats-container table {
        font-size: 11px;
    }
    .stats-container table colgroup col:nth-child(1) {
        width: 21px;
    }
    .stats-container table colgroup col:nth-child(3) {
        width: 95px;
    }
}

@media (max-width: 600px) {
    .league-table {
        font-size: 13px;
    }
}

@media (max-width: 500px) {
    .league-table {
        font-size: 11px;
    }
}

@media (max-width: 400px) {
    .league-table {
        font-size: 9px;
    }
}

@media (min-width: 768px) {
    .stats-second-row {
        margin-top: 15px;
    }
}