
.video-fixed-aspect-ratio {
    width: 100%;
    height: 0;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    position: relative;
}

.video-fixed-aspect-ratio > .video-js {
    width: 100%;
    top: 0;
    position: absolute;
}

/* Increase the clickable area of these sliders */
.video-js .vjs-slider-horizontal:after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    top: -0.6em;
    height: 1.5em;
}

.video-js .vjs-volume-bar.vjs-slider-horizontal:after {
    top: -0.9em;
    height: 2.1em;
}

.video-js .vjs-title-bar {
  pointer-events: none;
  padding: .5em;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s, visibility .3s;
}

.video-js .vjs-title-bar > div {
  text-shadow: 0 0 2px rgba(0,0,0,1);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.video-js .vjs-title-bar .vjs-playlist-title {
  font-size: 2em;
  color: #eee;
}

.video-js .vjs-title-bar .vjs-playlist-context {
  font-size: 1.5em;
  margin-top: 0.5em;
  color: #eee;
}

.video-js.vjs-paused.vjs-has-started:not(.vjs-ended) .vjs-title-bar,
.video-js.vjs-user-active.vjs-has-started:not(.vjs-ended) .vjs-title-bar,
.video-js:not(.vjs-has-started) .vjs-title-bar {
  background: linear-gradient(180deg, rgba(0,0,0,.7) 0%, rgba(0,0,0,0) 30%);
  opacity: 100;
  height: 100%;
  visibility: visible;
}

.video-js .playlist-suggestion {
    display: table;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.65);
    transition: opacity .3s, visibility .3s;
}

.video-js.vjs-ended .playlist-suggestion {
    opacity: 100;
    visibility: visible;
}

.video-js.vjs-ended .vjs-seek-button {
    display: none; /* Gets unnecessarily confusing - stick to only replay button */
}

.video-js .playlist-suggestion-inner {
    display: table-cell;
    vertical-align: middle;
    line-height: 1.7em;
    padding-left: 15px;
    padding-right: 15px;
}

@media (max-width: 767px) {
    .video-js .playlist-suggestion .thumbnail-metadata {
        font-size: 9px;
    }
    .video-js .playlist-suggestion-inner {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 30px;
    }
}

@media screen and (max-width: 480px) {
    .main-body-container .video-container {
        margin-right: -5px; /* 5px padding on .primary-content */
        margin-left: -5px;
        width: calc(100% + 10px);
    }
}

@media screen and (max-height: 480px) {
    .main-body-container .video-container {
        margin-right: -15px; /* 15px padding on .primary-content */
        margin-left: -15px;
        width: calc(100% + 30px);
        background-color: black;
    }

    .main-body-container .video-wrapper {
        margin: 0 auto;
        max-width: 132vh;
        max-width: calc(145vh - 52px);
    }
}

.embedded-player .video-container {
    width: 100%;
    background-color: black;
}

.embedded-player .video-wrapper {
    margin: 0 auto;
    max-width: 177.77777vh;
}

.video-overlay {
    position: absolute;
    z-index: 1;
    padding: 10px;
    background-color: black;
    background-color: rgba(43, 51, 63, 0.7);
}

.vjs-paused.vjs-has-started.vjs-touch .vjs-big-play-button {
    display: block;
}

.vjs-paused.vjs-ended.vjs-touch .vjs-big-play-button {
    display: none;
}
