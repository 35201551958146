.navbar-inverse {
    background-color: var(--primary-bg-color);
    margin-bottom: 0;
}

.navbar {
    border-radius: 0 !important;
    border: none;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: solid 6px var(--secondary-bg-color);
    border-radius: 0;
}

.navbar-inverse .navbar-nav > li > a {
    font-size: 2rem;
    color: white;
}

.navbar-inverse .navbar-toggle {
    border-color: rgba(255,255,255,0.5);
}

.navbar-brand {
    padding: 5px;
}

.magnifying-glass {
    position: relative;
    margin-left: 8px;
    top: 2px;
}

.navbar .navbar-brand {
    margin-top: 6px;
    margin-bottom: 6px;
    height: 70px;
    margin-right: 12px;
}

.navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .active > a {
    color: var(--secondary-bg-color);
    background-color: var(--primary-bg-color);
}

.navbar-inverse .navbar-nav > li > a:hover {
    color: var(--secondary-bg-color);
}

.navbar-nav .dropdown-menu {
    padding: 10px 0;
    font-size: 13px;
}

.navbar-nav .dropdown-header {
    color: var(--secondary-bg-color);
    text-transform: initial;
    font-size: 14px;
}

.navbar-nav .dropdown-menu .divider {
    background-color: var(--secondary-bg-color);
    margin: 10px 0;
}

.navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    font-weight: bold;
    color: white;
    outline: none !important;
}

.eliteserien-header-logo {
    height: 55px;
}

.navbar-cont {
    position: relative;
}

.navbar-language-cont {
    position: unset;
    gap: unset;
    display: flex;
    justify-content: center;
    gap: 7px;
    margin-bottom: 8px;
}

.navbar-language-cont img {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    cursor: pointer;
}

.language-separator {
    border: 1px solid gray;
    height: 18px;
}

@media (min-width: 768px) and (max-width: 888px){
    .navbar.cramped-header {
        font-size: 13px;
    }

    .navbar.cramped-header .navbar-nav > li > a {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (min-width: 768px) {
    .navbar-nav > li {
        padding: 16px 12px;
    }

    .navbar-nav > li.nav-external-btn > a {
        border: 2px solid var(--lighter-bg-color);
        border-radius: 4px;
        background-color: rgba(0,0,0,0.3);
        padding: 0 12px;
        height: 100%;
        padding-top: 12px; /* strip away the 2+2 border to make it equal other elems */
        padding-bottom: 10px;
    }

    .navbar-nav > li.nav-external-btn > a:hover {
        background-color: var(--secondary-bg-color);
    }

    .navbar-nav > li > .dropdown-menu {
        background-color: var(--primary-bg-color);
        border-top: 1px solid var(--primary-bg-color);
    }

    .navbar-right {
        margin-right: -30px;
    }

    .navbar-nav.navbar-right .dropdown-menu {
        text-align: center;
    }

    .navbar-language-cont {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        margin-bottom: unset;
    }

    .eliteserien-header-logo {
        height: 80px;
    }

    .navbar-language-cont img {
        width: 22px;
        height: 22px;
    }

    .language-separator {
        height: 22px;
    }
}

@media (max-width: 767px) {
    .navbar .navbar-brand {
        height: 56px;
        margin-top: 2px;
        margin-bottom: 2px;
        margin-left: 10px;
    }

    .navbar-toggle {
        margin: 12px;
    }

    .navbar-nav {
        margin: 0 -15px;
    }

    .navbar-nav:first-of-type {
        margin-top: 5px;
    }

    .navbar-nav:last-of-type {
        margin-bottom: 5px;
    }
}

@media (max-width: 480px) {
    .navbar .navbar-brand {
        margin-left: 5px;
    }
}

@media (min-width: 768px) and (max-width: 1047px) {
    .navbar-nav > li {
        padding: 16px 0;
    }
}
