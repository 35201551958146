
.small-game-container {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px 5px;
}

.small-game-card:hover {
    color: inherit;
}

.game-card-cont {
    display: flex;
    min-width: calc(25% - 10px);
    float: left;
    padding: 10px;
    color: black;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    cursor: pointer;
}

.game-card-cont:hover {
    background-color: rgba(0, 0, 0, .05);
    text-decoration: none;
    color: black;
}

.game-card-match {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
}

.game-card-team-and-logo {
    display: flex;
    align-items: center;
    gap: 7px;
    flex-grow: 1;
}

.game-card-team-and-logo img {
    height: 25px;
}

.game-card-team-and-logo div {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
}

.game-card-live {
    display: flex;
    align-items: center;
    height: 100%;
    min-width: 35px;
    gap: 5px;
}

.game-live-text {
    display: flex;
    align-items: center;
    padding: 2px 4px;
    background-color: #e2051b;
    color: white;
    border-radius: 3px;
    font-size: 1.2rem;
    letter-spacing: .3px;
}

.game-card-score {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-right: 5px;
    font-size: 1.4rem;
    gap: 5px;
}

.game-card-score div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(50% - 2px);
    padding-top: 3px;
}

.game-card-text {
    height: 100%;
    display: flex;
    align-items: center;
}

@media (max-width: 1200px) {
    .game-card-cont {
        min-width: calc(50% - 10px);
    }
}

@media (max-width: 768px) {
    .game-card-team-and-logo {
        gap: 10px;
    }
    .game-card-team-and-logo div,
    .game-card-score {
        font-size: 1.4rem;
    }
    .game-card-team-and-logo img {
        height: 22px;
    }
    .small-game-container {
        gap: 7px 3px;
    }
    .game-card-cont {
        min-width: 100%;
    }
    .game-card-cont {
        padding: 7px;
    }
    .game-card-score {
        margin-right: 15px;
    }
}
