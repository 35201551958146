.thumbnail-container {
    position: relative;
    display: block;
}

.thumbnail-container .thumbnail-img {
    display: block;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: var(--playlist-fade-color);
    border-radius: 4px;
}

.thumbnail-metadata {
    position: absolute;
    cursor: default;
    min-width: 64px;
    font-size: 13px;
    color: white;
    font-weight: bold;
    text-decoration: none;
}

.thumbnail-metadata > span {
    display: block;
    padding: 8px 12px;
    line-height: 1em;
    z-index: 1;
    position: relative;
    text-shadow: 0 0 4px #000;
}

.thumbnail-metadata:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .6);
}

.thumbnail-metadata-duration {
    top: 0;
    left: 0;
}

.thumbnail-metadata-duration:after {
    border-top-left-radius: 4px;
    border-bottom-right-radius: 10px;
}

.thumbnail-metadata-views {
    top: 0;
    right: 0;
}

.thumbnail-metadata-views:after {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 10px;
}

.thumbnail-metadata-views svg {
    position: relative;
    top: 2px;
    margin-right: 4px;
    -webkit-filter: drop-shadow( 0 0 4px #000 );
    filter: drop-shadow( 0 0 4px #000 );
}

.thumbnail-eye-icon {
    width: 13px;
    height: 13px;
    margin-top: -3px;
    margin-right: 4px;
}

.thumbnail-live-indicator {
    border-radius: 100%;
    background: red;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 4px;
}


/* Mobile overrides */
@media (max-width: 680px) {
    .thumbnail-metadata {
        font-size: 9px;
        min-width: 46px;
    }
    .thumbnail-metadata-views:after {
        border-bottom-left-radius: 4px;
    }
    .thumbnail-metadata-duration:after {
        border-top-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    .thumbnail-metadata > span {
        padding: 4px 6px;
    }
    .thumbnail-eye-icon {
        width: 8px;
        height: 8px;
        margin-top: -2px;
        margin-right: 3px;
    }
}
