body {
    font-family: var(--primary-font-family);
    color: var(--primary-dark-font-color);
    background-color: var(--body-background-color);
    overflow-x: hidden;
}

.content-container {
    display: flex;
}

.main-body-container {
    padding-right: 0;
    padding-left: 0;
}

.no-pad-left {
    padding-left: 0 !important;
}

.no-pad-right {
    padding-right: 0 !important;
}

.btn.btn-primary {
    background-color: var(--lighter-bg-color);
    border-color: #ccc;
}

.btn.btn-primary:hover {
    background-color: var(--primary-bg-color);
}

body.modal-open {
    /* No idea why it does this, cant find any positive effect apart from breaking things */
    padding-right: 0 !important;
}

@media (min-width: 768px) {
    .container {
        width: calc(100% - 30px);
    }
}

@media (min-width: 837px) {
    .container {
        width: calc(100% - 30px);
    }
}

@media (min-width: 992px) {
    .container {
        width: 975px;
    }
}

@media (min-width: 1047px) {
    .container {
        width: 1030px;
    }
}

@media (min-width: 1217px) {
    .container {
        width: 1200px;
    }
}

@media screen and (max-width: 480px) {
    .content-container.primary-content > div {
        padding-left: 10px;
        padding-right: 10px;
        overflow-x: hidden;
    }

    .search-container,
    .primary-content > div > .voffset4 {
        margin-top: 0;
    }
}

@media screen and (max-height: 480px) {
    .content-container.primary-content > div {
        overflow-x: hidden;
    }

    .search-container {
        margin-top: 0;
    }
}
