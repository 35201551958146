
.dropdown-menu li.active > a,
.dropdown-menu li.active > a:hover,
.dropdown-menu li.active > a:focus,
.daterangepicker .ranges li.active,
.daterangepicker .calendar-table td.active {
    background-color: var(--lighter-bg-color);
    color: var(--primary-light-font-color);
}

.daterangepicker .ranges li {
    font-size: 1.5rem;
}

.search-query-section {
    min-height: 175px;
}

.daterangepicker {
    font: inherit;
}
