
.login-prompt .modal-dialog {
    margin-top: 67px;
}

.login-prompt .modal-header {
    border-bottom: none;
}

.login-prompt .modal-header button.close {
    position: absolute;
    right: 15px;
    font-size: 32px;
    margin-top: -7px;
    opacity: 0.5;
}

.login-prompt .modal-header button.close:hover {
    opacity: 0.9;
}

.login-prompt .modal-body {
    padding-bottom: 25px;
    padding-top: 0;
}

.login-prompt .modal-body a.btn {
    width: 90%;
    font-weight: bold;
}

.login-prompt .modal-body a.btn.btn-success {
    color: white;
    background-color: #14B516;
}

.login-prompt .modal-body a.btn.btn-success:hover,
.login-prompt .modal-body a.btn.btn-success:active {
    background-color: #108b12;
}

.login-prompt .login-header {
    color: #532681;
    font-size: 22px;
    margin-bottom: 15px;
}

.login-prompt img {
    height: 36px;
}

@media (min-width: 768px) {
    .login-prompt .modal-dialog {
        width: 520px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 87px;
    }
}
