@import "../../node_modules/bootstrap/dist/css/bootstrap.css";
@import "sef-fonts.css";
@import "utility.css";

/* Define color variables */
:root {
    --primary-font-family: Gilroy;
    --header-font-family: Gilroy;
    --primary-bg-color: black;
    --secondary-bg-color: #ffd600;
    --lighter-bg-color: #lightgrey;
    --stripe-bg-color: #101010;
    --body-background-color: #F5F6FA;
    --content-background-color: white;
    --primary-dark-font-color: #333;
    --primary-light-font-color: white;
    --default-link-color: #174B93;
    --hover-link-color: #12326e;
    --pagination-color: #E73088;
    --pagination-hover-color: #C71068;
}

/* Customize default bootstrap pagination styling to fit our page */
.pagination {
    margin: 8px;
    font-size: 1.6rem;
}

.pagination > li > a,
.pagination > li > span,
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
    line-height: 1em;
    color: #272727;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    background-color: var(--pagination-color);
    border-color: var(--pagination-color);
    color: var(--primary-light-font-color);
}

.table {
    margin-bottom: initial;
    font-weight: bold;
}

.input-group-xs>.input-group-addon {
    height: 22px;
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

a {
    color: var(--default-link-color);
}

a:hover, a:focus {
    color: var(--hover-link-color);
}